import React from 'react'

const ArrowTop = (props) => (
  <svg viewBox="0 0 44.57 24.44" {...props} width="24px" height="14px">
    <path
      d="M2.56,24A1.5,1.5,0,1,1,.44,21.88L21.2,1.05,21.32,1l.95-1L44.13,21.85A1.51,1.51,0,0,1,42,24L22.26,4.24Z"
      fill="#06101a"
    />
  </svg>
)

export default ArrowTop
