import React from 'react'

const PhoneIcon = (props) => (
  <svg width={22.862} height={23} viewBox="0 0 22.862 23" {...props}>
    <defs>
      <style>{'.a{fill:#bba167;}'}</style>
    </defs>
    <path
      className="a"
      d="M519.559,281.7a18.379,18.379,0,0,1-12.686-5.673c-3.816-3.816-5.879-8.457-5.673-13.1v-.206l.1-.1c.309-.413.619-.722.928-1.135a10.216,10.216,0,0,1,4.229-2.682.773.773,0,0,1,.516-.1c.516,0,1.444.309,1.65.825.516,1.547,1.238,3.61,1.753,5.466a1.9,1.9,0,0,1-.516,1.753l-1.857,1.135c-.1.1-.206.206-.206.309a1.355,1.355,0,0,0,.309,1.031,21.589,21.589,0,0,0,2.475,2.888,30.776,30.776,0,0,0,2.888,2.475c.516.413,1.135.413,1.341.1l1.135-1.857c.309-.516,1.134-.516,1.444-.516h.309a29.639,29.639,0,0,1,5.57,1.753,2.1,2.1,0,0,1,.722,2.063,14.014,14.014,0,0,1-2.682,4.229,10.966,10.966,0,0,1-1.135.928l-.1.1Zm-17.327-18.462a17.064,17.064,0,0,0,5.363,12.067l.1.1a17.266,17.266,0,0,0,11.964,5.363h.1a5.9,5.9,0,0,0,.825-.722,10.158,10.158,0,0,0,2.475-3.816,1.111,1.111,0,0,0-.206-.825,26.454,26.454,0,0,0-5.26-1.65,5.815,5.815,0,0,0-.722.1l-1.135,1.753a2.051,2.051,0,0,1-2.888.206,23.293,23.293,0,0,1-2.991-2.578,33.29,33.29,0,0,1-2.578-2.991,2.577,2.577,0,0,1-.516-1.857,1.754,1.754,0,0,1,.619-1.031l1.856-1.135c0-.1.1-.516.1-.619-.516-1.857-1.238-3.919-1.753-5.466a3.659,3.659,0,0,0-.722-.1h-.1a10.617,10.617,0,0,0-3.816,2.475A4.649,4.649,0,0,0,502.232,263.238Z"
      transform="translate(-501.186 -258.7)"
    />
  </svg>
)

export default PhoneIcon
