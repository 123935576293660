import React from 'react'

const EnvelopeIcon = (props) => (
  <svg width={24.687} height={17.617} viewBox="0 0 24.687 17.617" {...props}>
    <defs>
      <style>{'.a{fill:#bba167;}'}</style>
    </defs>
    <g transform="translate(0 0)">
      <path
        className="a"
        d="M155.755,217.817H135.332a2.131,2.131,0,0,1-2.132-2.132V202.332a2.131,2.131,0,0,1,2.132-2.132h20.423a2.131,2.131,0,0,1,2.132,2.132v13.353A2.292,2.292,0,0,1,155.755,217.817Zm-20.423-16.383a1.006,1.006,0,0,0-1.01,1.01v13.241a1.006,1.006,0,0,0,1.01,1.01h20.423a1.006,1.006,0,0,0,1.01-1.01V202.332a1.006,1.006,0,0,0-1.01-1.01H135.332Z"
        transform="translate(-133.2 -200.2)"
      />
    </g>
    <g transform="translate(0.178 8.248)">
      <path
        className="a"
        d="M155.735,216.92H135.313a1.956,1.956,0,0,1-1.908-1.347.742.742,0,0,1,.112-.673l7.967-7.069a.555.555,0,0,1,.786.785l-7.63,6.733a.97.97,0,0,0,.673.337h20.423a.8.8,0,0,0,.673-.337l-7.743-6.845a.555.555,0,1,1,.785-.785l8.079,7.182a.391.391,0,0,1,.112.561A2.129,2.129,0,0,1,155.735,216.92Z"
        transform="translate(-133.359 -207.55)"
      />
    </g>
    <g transform="translate(0.151 0.112)">
      <path
        className="a"
        d="M145.527,212.756a.412.412,0,0,1-.337-.112L133.52,202.32c-.224-.112-.224-.449-.112-.673a2.186,2.186,0,0,1,1.908-1.347h20.423a1.956,1.956,0,0,1,1.908,1.347.493.493,0,0,1-.112.561l-11.558,10.324C145.751,212.643,145.639,212.756,145.527,212.756Zm-10.885-11.109,10.885,9.763,10.885-9.763a.97.97,0,0,0-.673-.337H135.316A3.736,3.736,0,0,0,134.642,201.647Z"
        transform="translate(-133.334 -200.3)"
      />
    </g>
  </svg>
)

export default EnvelopeIcon
